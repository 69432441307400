import { StratoxDom as $ } from '../../node_modules/stratox/src/StratoxDom.js';

export const app = {
    init: function() {
        app.config = {
            direction: true,
            directionX: true,
            header: $("#header"),
            smartBtn: $("#wa-smart-btn"),
            navItems: {}
        };

        app.ready();
        return this;

    }, ready: function() {

        setTimeout(app.typewiter, 500);

        if('IntersectionObserver' in window) {
            app.observeArticle(".wa-observe", 0.8);
        }
        
        $(".wa-anchor-btn").click(function(e) {
            const btn = $(this);
            if(btn.hasClass("nav-item")) app.config.header.removeClass("nav-active");
        });
        
        app.config.smartBtn.click(function(e) {
            e.preventDefault();
            if(app.config.header.hasClass("nav-active")) {
                app.config.header.removeClass("nav-active");
            } else {
                app.config.header.addClass("nav-active");
            }
        });

        let prevScroll = 0, prevScrollX = 0, activeHeader = false;
        $(window).scroll(function(e) {
            let scrollTop = window.scrollY, scrollLeft = window.scrollX;
            app.config.direction = !(scrollTop < prevScroll);
            app.config.directionX = !(scrollLeft < prevScrollX);

            prevScroll = scrollTop;
            prevScrollX = scrollLeft;

            if(!activeHeader && scrollTop >= 10) {
                activeHeader = true;
                app.config.header.addClass("active");
            }

            if(activeHeader && scrollTop < 10) {
                activeHeader = false;
                app.config.header.removeClass("active");
            }
        });

    }, observeArticle: function(objStr, threshold) {
        $(objStr).each(function() {
            let elem = $(this), height = elem.height(), th = threshold, el = elem.get(0);
            if(height > (window.innerHeight * threshold)) th = ((window.innerHeight * threshold) / height) * threshold;
            el.data = { direction: ((elem.data("direction") === "horizontal") ? "horizontal" : "vertical") } ;
            const observer = new IntersectionObserver(app.observeArticleFeed, { threshold: th });
            observer.observe(el); 
        });

    }, observeArticleFeed: function(entries, observer) {
        $.each(entries, function(k, entry) {

            let navItemKey = entry?.target?.dataset?.selectNav;

            if(entry.isIntersecting) {
                if(!app.config.navItems[navItemKey]) app.config.navItems[navItemKey] = $("#"+navItemKey);
                if(app.config.navItems[navItemKey]) app.config.navItems[navItemKey].addClass("active");
                entry.target.classList.add("observer-active");

            } else {
                if((entry.target.data.direction === "vertical" && !app.config.direction) || 
                    (entry.target.data.direction === "horizontal" && app.config.directionX)) {
                    entry.target.classList.remove("observer-active"); 
                }
                if(app.config.navItems[navItemKey]) app.config.navItems[navItemKey].removeClass("active");
            }

        });

    }, typewiter: function() {
        $(".wa-typewiter-text").each(function() {
            const obj = $(this), text = obj.text(), length = text.length, 
            textHolder = obj.parent().append('<div class="wa-typewiter-holder abs top left z-10"><span class="wa-typewiter-insert"></span></div>'),
            textObj = textHolder.find(".wa-typewiter-insert");
            let index = 0, ivt = setInterval(function() {
                if(index > (length-1)) {
                    textHolder.addClass("complete");
                    clearInterval(ivt);
                } else {
                    textObj.append(text[index]);
                    index++;
                }
            }, 100);
        });
    }
}


$(document).ready(app.init);
